import antdPtBr from "antd/es/locale/pt_BR";
import ptMsg from "../locales/pt_BR.json";

const PtLang = {
	antd: antdPtBr,
	locale: "pt-BR",
	messages: {
		...ptMsg,
	},
};
export default PtLang;
