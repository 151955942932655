// TODO: após clonar o skeleton, ajustar os links de ambientes

const dev = {
	API_ENDPOINT_URL: "http://localhost:8087/clinipro/dimo-mail/api",
	PUBLIC_PREFIX_PATH: "",
};

const prod = {
	API_ENDPOINT_URL:
		"https://api.clinipro.com.br/clinipro-backend2/clinipro/dimo-mail/api",
	PUBLIC_PREFIX_PATH: "",
};

const test = {
	API_ENDPOINT_URL: "http://localhost:8099/dimail/dimo-mail/api",
	PUBLIC_PREFIX_PATH: "",
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case "development":
			return dev;
		case "production":
			return prod;
		case "test":
			return test;
		default:
			break;
	}
};

export const env = getEnv();
