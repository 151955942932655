import {
	BarChartOutlined,
	SettingOutlined,
	MailOutlined,
	ShopOutlined,
	UserOutlined,
	UsergroupAddOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
	PermissaoGrupoUsuarioEnum,
	PermissaoUsuarioEnum,
	PermissaoDashboardEnum,
	PermissaoEmailEnum,
	PermissaoFilialEnum,
} from "enum/permissao-enum";

const dashBoardNavTree = [
	{
		key: "dashboard",
		path: `${APP_PREFIX_PATH}/dashboard`,
		title: "dashboard",
		icon: BarChartOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoDashboardEnum.VISUALIZAR,
	},
	{
		key: "envio-email",
		path: `${APP_PREFIX_PATH}/envio-email`,
		title: "envio-email",
		icon: MailOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoEmailEnum.ENVIAR,
	},
	{
		key: "config-email",
		path: `${APP_PREFIX_PATH}/config-email`,
		title: "config-email",
		icon: SettingOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoEmailEnum.CONFIGURAR,
	},
	{
		key: "lista-filial",
		path: `${APP_PREFIX_PATH}/lista-filial`,
		title: "lista-filial",
		icon: ShopOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoFilialEnum.VISUALIZAR,
	},
	{
		key: "lista-usuarios",
		path: `${APP_PREFIX_PATH}/lista-usuarios`,
		title: "lista-usuarios",
		icon: UserOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoUsuarioEnum.VISUALIZAR,
	},
	{
		key: "lista-grupos-usuario",
		path: `${APP_PREFIX_PATH}/lista-grupos-usuario`,
		title: "lista-grupos-usuario",
		icon: UsergroupAddOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoGrupoUsuarioEnum.VISUALIZAR,
	},
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
