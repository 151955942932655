export const PermissaoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_USUARIO",
	CRIAR: "CRIAR_USUARIO",
	EDITAR: "EDITAR_USUARIO",
	EXCLUIR: "EXCLUIR_USUARIO",
};

export const PermissaoGrupoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_GRUPO_USUARIO",
	CRIAR: "CRIAR_GRUPO_USUARIO",
	EDITAR: "EDITAR_GRUPO_USUARIO",
	EXCLUIR: "EXCLUIR_GRUPO_USUARIO",
};

export const PermissaoDashboardEnum = {
	VISUALIZAR: "VISUALIZAR_DASHBOARD",
};

export const PermissaoEmailEnum = {
	ENVIAR: "ENVIAR_EMAIL",
	CONFIGURAR: "CONFIGURAR_EMAIL",
};

export const PermissaoFilialEnum = {
	VISUALIZAR: "VISUALIZAR_FILIAL",
	CRIAR: "CRIAR_FILIAL",
	EDITAR: "EDITAR_FILIAL",
	EXCLUIR: "EXCLUIR_FILIAL",
};
