import antdFrFR from "antd/es/locale/fr_FR";
import frMsg from "../locales/fr_FR.json";

const FrLang = {
	antd: antdFrFR,
	locale: "fr-FR",
	messages: {
		...frMsg,
	},
};
export default FrLang;
