import fetch from "auth/FetchInterceptor";
import { UPDATE_USER } from "redux/constants/User";
import { UPDATE_PERMISSOES } from "redux/constants/Permissoes";
import store from "redux/store";

const MenuService = {};

/*
    Injeta na store do usuário os dados do menu
*/
// TODO: após clonar o skeleton, ajustar essa função
MenuService.populateMenuData = async function () {
	const authState = store.getState().auth;
	if (authState.token) {
		const menuData = await MenuService.getMenuData();

		store.dispatch({
			type: UPDATE_USER,
			name: menuData.data.user.email,
			role: "Usuário",
			id_empresa: menuData.data.empresa.id_empresa,
		});

		store.dispatch({
			type: UPDATE_PERMISSOES,
			permissoes: menuData.data.permissoes,
		});
	}
};

// TODO: após clonar o skeleton, ajustar essa função
MenuService.getMenuData = async function () {
	return fetch({
		url: "/v1/rest/menu",
		method: "get",
	});
};

export default MenuService;
