import enLang from "./entries/en_US";
import zhLang from "./entries/zh_CN";
import frLang from "./entries/fr_FR";
import jaLang from "./entries/ja_JP";
import PtLang from "./entries/pt_BR";

const AppLocale = {
	en: enLang,
	zh: zhLang,
	fr: frLang,
	ja: jaLang,
	pt: PtLang,
};

export default AppLocale;
