import { UPDATE_USER, ID_EMPRESA, RESET_USER } from "../constants/User";

const initUser = {
	name: null,
	role: null,
	id_empresa: null,
};

const user = (state = initUser, action) => {
	switch (action.type) {
		case UPDATE_USER:
			localStorage.setItem(ID_EMPRESA, action.id_empresa);
			return {
				...state,
				name: action.name,
				role: action.role,
				id_empresa: action.id_empresa,
			};
		case RESET_USER:
			return initUser;
		default:
			return state;
	}
};

export default user;
