import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/dashboard`}
					component={lazy(() => import(`./dashboard`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/envio-email`}
					component={lazy(() => import(`./envio-email`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/config-email`}
					component={lazy(() => import(`./config-email`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-filial`}
					component={lazy(() => import(`./lista-filial`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/filial/:id`}
					component={lazy(() => import(`./filial`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/filial`}
					component={lazy(() => import(`./filial`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-usuarios`}
					component={lazy(() => import(`./lista-usuarios`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/usuario/:id`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/usuario`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-grupos-usuario`}
					component={lazy(() => import(`./lista-grupos-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario/:id`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Redirect
					from={`${APP_PREFIX_PATH}`}
					to={`${APP_PREFIX_PATH}/dashboard`}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
