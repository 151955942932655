import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import history from "../history";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";
import store from "redux/store";

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";
const EMPRESA_ID = "id_empresa";

// API Request interceptor
service.interceptors.request.use(
	(config) => {
		const jwtToken = config.token ?? localStorage.getItem(AUTH_TOKEN);
		if (jwtToken) {
			config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
		}

		if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
			history.push(ENTRY_ROUTE);
			window.location.reload();
		}

		// const user = localStorage.getItem('user');
		const state = store.getState();
		const user = state.user;

		if (user?.id_empresa) {
			config.headers[EMPRESA_ID] = user?.id_empresa;
		} else {
			config.headers[EMPRESA_ID] = undefined;
		}

		return config;
	},
	(error) => {
		// Do something with request error here
		notification.error({
			message: "Error",
		});
		Promise.reject(error);
	},
);

// API respone interceptor
service.interceptors.response.use(
	(response) => {
		return { data: response.data, status: response.status };
	},
	(error) => {
		let notificationParam = {
			message: "",
		};

		if (!error.response) {
			notificationParam.message = "Erro inesperado";
			notificationParam.description = error.message;
			localStorage.removeItem(AUTH_TOKEN);
			notification.error(notificationParam);
			return Promise.reject(error);
		}

		// Remove token and redirect
		if (error.response.status === 400 || error.response.status === 403) {
			notificationParam.message = "Authentication Fail";
			notificationParam.description = "Please login again";
			localStorage.removeItem(AUTH_TOKEN);
			history.push(ENTRY_ROUTE);
			window.location.reload();
		}

		if (error.response.status === 401) {
			notificationParam.message = "Falha na autenticação";
		}

		if (error.response.status === 404) {
			notificationParam.message = "Not Found";
		}

		if (error.response.status === 500) {
			notificationParam.message = "Internal Server Error";
		}

		if (error.response.status === 508) {
			notificationParam.message = "Time Out";
		}

		notification.error(notificationParam);

		return Promise.reject(error);
	},
);

export default service;
